import classes from './About.module.css';
import me from '../../assets/me.jpg';
import {about} from '../../data/about';

const About = () => {
    return (
        <div id="about-me" className={classes.about}>
            <div className={classes['about-left']}>
                <div className={classes['about-card-bg']}></div>
                <div className={classes['about-card']}>
                    <img src={me} alt="" className={classes['about-img']}></img>
                </div>
            </div>
            <div className={classes['about-right']}>
            <h1 className={classes['about-title']}>About Me</h1>
                {about.map((item) => (
                        /* <p className={classes['about-sub']}>
                            {item.desc1}
                        </p> */
                        <p key={item.id}  className={classes['about-desc']}>
                            {item.desc2}
                        </p>
                ))}
            </div>
        </div>
    )
}

export default About
