import classes from './Toogle.module.css';
import Sun from "../../assets/sun.png";
import Moon from "../../assets/moon.png";
import { useContext } from "react";
import { ThemeContext } from "../../context/theme";

const Toogle = () => {
  const theme = useContext(ThemeContext);

  const handleClick = () => {
    theme.ToggleDarkMode();
  };
  return (
    <div className={classes.toggle}>
      <img src={Sun} alt="" className={classes['toggle-icon']} />
      <img src={Moon} alt="" className={classes['toggle-icon']}/>
      <div
        className={classes['toggle-button']}
        onClick={handleClick}
        style={{ left: theme.darkMode ? 0 : 25 }}
      ></div>
    </div>
  );
};

export default Toogle;