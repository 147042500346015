
import classes from './Projects.module.css';
import { useContext } from 'react';
import { ThemeContext } from '../../context/theme';
const Projects = (props) => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.darkMode; 
    return (
        <div id="projects" className={classes.project}>
            <div className={classes['project-browser']}>
                <div className={classes['project-circle']}></div>
                <div className={classes['project-circle']}></div>
                <div className={classes['project-circle']}></div>
            </div>
            <img src={props.img} alt="" className={classes['project-img']}/>
                <div className={classes['project-image-overlay']}>
                    <a href={props.link} target="_blank" rel="noreferrer" style={{color : darkMode ? "white" : "#222"  }} >
                        <div className={classes['project-image-overlay-title']}>{props.title}</div>
                    </a>
                    {props.desc.map((item,index) => (
                        <p key={index} className={classes['project-image-overlay-desc']}>
                            - {item.desc}
                        </p>
                    ))}
                </div>

            
        </div>
    )
}

export default Projects
