
export const achivements = [
    {
      id: 1,
      date: '24 Mar 2021 - 24 Mar 2024',
      name : 'AWS Certified Solution Architect - Associate',
      desc : 'Issued by Amazon Web Services Training and Certification',
      title : 'Badges',
      link : 'https://www.credly.com/badges/b8e57b83-1307-4e95-8656-3bf509cc7f18'
    },
    {
      id: 2,
      date: '13 Nov 2020 - 13 Nov 2023',
      name : 'AWS Certified Developer - Associate',
      desc : 'Issued by Amazon Web Services Training and Certification',
      title : 'Badges',
      link : 'https://www.credly.com/badges/02465813-850d-49dc-9153-ec8c5f68ed4f'
    },
    {
      id: 3,
      date: 'Nov 2019 -',
      name : 'Gold Winner of Best HR Tech - HR Information System',
      desc : 'Awarded to Juztalent by HRM Asia',
      title : 'Article',
      link : 'https://hrmasia.com/readers-choice-2019-best-hr-tech-hr-information-system/'
    },
  ];