
export const edu = [
  {
    id: 1,
    date : '2015 - 2018',
    school : 'Republic Polytechnic',
    course : 'Diploma in Business Information System with MERIT',
    gpa: 'GPA 3.77/4.00',
  },
 
];