import Angular from '../assets/skills/angular.svg'
import aws from '../assets/skills/aws.svg'
import javascript from '../assets/skills/javascript.svg'
import react from '../assets/skills/react-2.svg'
import typescript from '../assets/skills/typescript.svg'
import node from '../assets/skills/nodejs.svg'
import mysql from '../assets/skills/mysql-6.svg'
import laravel from '../assets/skills/laravel.svg'
import springBoot from '../assets/skills/spring-boot.svg'
import Java from '../assets/skills/java.svg'
import postgres from '../assets/skills/postgres.svg'
import php from '../assets/skills/php.svg'
export const skills = [
  {
    id: 1,
    img: Angular,
    title: "Angular",
  },
  {
    id: 2,
    img: react,
    title: "React",
  },
  {
    id: 3,
    img: javascript,
    title: "Javascript",
  },
  {
    id: 4,
    img: typescript,
    title: "TypeScript",
  },
  {
    id: 5,
    img: aws,
    title: "AWS",
  },
  {
    id: 6,
    img: node,
    title: "Node.js",
  },
  {
    id: 7,
    img: php,
    title: "Php",
  },
  {
    id: 8,
    img: Java,
    title: "Java",
  },
  {
    id: 9,
    img: springBoot,
    title: "Spring Boot",
  },
  {
    id: 10,
    img: laravel,
    title: "Laravel",
  },
  {
    id: 11,
    img: postgres,
    title: "PostgreSQL",
  },
  {
    id: 12,
    img: mysql,
    title: "Mysql",
  },
];