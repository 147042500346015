import classes from './Menu.module.css'
import {ThemeContext} from '../../context/theme'
import { useContext } from 'react';
const Menu = (props) => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.darkMode; 
    return (
        <div style={{backgroundColor:darkMode ? "white" : "#222" , color :darkMode ? "#222" : "white"}} className={`${classes.menu} ${(props.menuOpen && classes.active)}`}>
            <ul>
                <li onClick={()=>props.setMenuOpen(false)}>
                    <a href="#intro">Intro</a>
                </li>
                <li onClick={()=>props.setMenuOpen(false)}>
                    <a href="#about-me">About Me</a>
                </li>
                <li onClick={()=>props.setMenuOpen(false)}>
                    <a href="#skills">Skills</a>
                </li>
                <li onClick={()=>props.setMenuOpen(false)}>
                    <a href="#exp">Experience</a>
                </li>
                <li onClick={()=>props.setMenuOpen(false)}>
                    <a href="#projects">Projects</a>
                </li>
                <li onClick={()=>props.setMenuOpen(false)}>
                    <a href="#contact-me">Contact</a>
                </li>
            </ul>
        </div>
    )
}

export default Menu
