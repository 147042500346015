import classes from './Contact.module.css'
import {useContext , useRef, useState } from "react";
import { ThemeContext } from '../../context/theme';
import LoadingSpinner from '../../UI/Loading/Loading';
const Contact = () => {
    const name = useRef();
    const email = useRef();
    const message = useRef();
    const [done, setDone] = useState(false)
    const theme = useContext(ThemeContext);
    const darkMode = theme.darkMode;
    const [isLoading, setisLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const nameInput =  name.current.value; 
        const emailInput =  email.current.value; 
        const messageInput =  message.current.value; 

        const emailData = 'name=' + nameInput + '&email=' + emailInput + '&message=' + messageInput
        setisLoading(true);

        try {
            const response = await fetch(
                'https://iwhma6f4r6.execute-api.ap-southeast-1.amazonaws.com/prod/contactme?' + emailData  ,
                {
                    method : 'POST'
                }  
            );
        
            if (!response.ok) {
                throw new Error('Request failed!');
            }
            setDone(true)
            setTimeout(function(){ 
                setDone(false)
            }, 5000);
        } catch (err) {

        }
        setisLoading(false);
    };
    return (
        <div id="contact-me" className={classes.contact}>
            <div className={classes['contact-bg']}></div>
            <div className={classes['contact-wrapper']}>
            <div className={classes['contact-left']}>
                <h1 className={classes['contact-title']}>Contact me</h1>
                <div className={classes['contact-info']}>
                <div className={classes['contact-info-item']}>
                    <a  style={{color : darkMode ? "white" : '#616161' }}  href="mailto: hauyinlim@gmail.com" ><i className="fas fa-envelope"></i> hauyinlim@gmail.com </a>
                </div>
                <div className={classes['contact-info-item']}>
                    <a style={{color : darkMode ? "white" : '#616161' }}  href="https://sg.linkedin.com/in/hau-yin-lim-14a215159" rel="noreferrer" target="_blank"><i className="fab fa-linkedin"></i> Linkedin </a>
                </div>
                <div className={classes['contact-info-item']}>
                    <a style={{color : darkMode ? "white" : '#616161' }}  href="https://github.com/limhauyin" rel="noreferrer" target="_blank"><i className="fab fa-github"> GitHub </i></a>
                </div>
                <div className={classes['contact-info-item']}>
                    <a style={{color : darkMode ? "white" : '#616161' }}  href="https://wa.me/6587920816" rel="noreferrer" target="_blank"><i className="fab fa-whatsapp"> Whatsapp </i></a>
                </div>
                </div>
            </div>
            <div className={classes['contact-right']}>
                {isLoading && <LoadingSpinner/>}
                <p className={classes['contact-desc']}>
                <b>What’s your story?</b> Get in touch with me !
                </p>
                <form onSubmit={handleSubmit}>
                <input ref={name} style={{backgroundColor: darkMode && "#333",color : darkMode && "white" }} type="text" placeholder="Name" name="user_name" />
                <input ref={email} style={{backgroundColor: darkMode && "#333",color : darkMode && "white" }} type="text" placeholder="Email" name="user_email" />
                <textarea ref={message} style={{backgroundColor: darkMode && "#333",color : darkMode && "white" }} rows="5" placeholder="Message" name="message" />
                <button style={{backgroundColor: !darkMode && "#616161" ,color : !darkMode && "white"  }} >Submit</button>
                {done && <p>Thank you, I Will get back to you soon !</p>}
                </form>
            </div>
            </div>
      </div>
    )
}

export default Contact
