import classes from './Skills.module.css';
import { skills } from '../../data/skills';


const Skills = (props) => {
    return (
        <div id="skills" className={classes.skills} >
            <div className={classes['skills-headings']}>
                <h2> SKILLS </h2>
            </div>
            <section className={classes.container}>
                {skills.map((item) => (
                    <div key={item.id} className={classes['container-div']}>
                        <div className={classes.icon}>
                            <span className={classes['icon-img']}>
                                <img src={item.img} alt="" className="p-img" />
                            </span>    
                            <h3>{item.title}</h3>
                        </div>
                    </div>
                ))}
               
            </section>
        </div>
    )
}

export default Skills
