import classes from './Topbar.module.css';


const Topbar = (props) => {
    return (
        <div className={`${classes.topbar} ${(props.menuOpen && classes.active)}`}>
        <div className={classes.wrapper}>
          <div className={classes.left}>
            <a href="#intro" className={classes.logo}>
              HY.
            </a>
          
          </div>
          <div className={classes.right}>
            <div className={classes.hamburger} onClick={()=>props.setMenuOpen(!props.menuOpen)}>
              <span className={classes.line1}></span>
              <span className={classes.line2}></span>
              <span className={classes.line3}></span>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Topbar
