import Projects from '../Projects/Projects';
import classes from './ProjectList.module.css';
import {products} from "../../data/project";


const ProjectList = () => {
    return (
        <div className={classes.projectList}>
            <div className={classes['projectList-texts']}>
                <h1 className={classes['projectList-title']}>
                    Projects
                </h1>
                {/* <p className={classes['projectList-description']}>
                        adsasdadsdsa
                </p> */}
            </div>
            <div className={classes['projectList-list']}>
                {products.map((item) => (
                    <Projects key={item.id} img={item.img} title={item.title} desc={item.desc} link={item.link} />
                ))}
            </div>
        </div>
    )
}

export default ProjectList
