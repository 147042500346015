import React from 'react';

const P = (props) => {
    return (    
        <React.Fragment>
            {props.desc.map((item) => (              
                <p key={item.id}>{item.description}</p>
            ))}
        </React.Fragment>
    )
}

export default P
