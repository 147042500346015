import telegram from '../assets/project/telegram.png'
import angularCalculator from '../assets/project/angular-calculator.png';
import todolist from '../assets/project/todolist.png';
import portfolio from '../assets/project/portfolio.png';
export const products = [
  {
    id: 1,
    img: telegram,
    title : 'Automated Telegram Message',
    desc : [
      {id : 1 , desc : 'A Node.js Lambda functions that integrate with my telegram.'},
      {id : 2, desc : 'Scheduled to execute Lambda functions using EventBridge.'},
    ]
  },
  {
    id: 2,
    img: portfolio,
    title : 'Personal Portfolio',
    desc : [
      {id : 1 , desc : 'AWS CodeCommit as version control system.'},
      {id : 2, desc : 'AWS CodePipeline deploys a static website to my S3 bucket.'},
      {id : 3, desc : 'Cloudfront to serve static website hosted on S3.'},
      {id : 4, desc : 'API Gateway with Lambda integration and SNS Notification for contact me section.'},
    ]
  },
  {
    id: 3,
    img: angularCalculator,
    title : 'Angular Calculator',
    desc : [
      {id : 1 , desc : 'A simple calculator on Angular.'},
      {id : 2, desc : 'Hosted using Amazon S3 and distributed via Cloudfront.'},
    ],
    link : 'https://d17iocss8legwp.cloudfront.net/'
  },
  {
    id: 4,
    img: todolist,
    title : 'To Do List (MEAN STACK)',
    desc : [
      {id : 1 , desc : 'Develop by MongoDB, Express, Angular and Node.js'},
      {id : 2, desc : 'Create web API with HTTP endpoint with Lambda using API Gateway.'},
      {id : 3, desc : 'Deploy backend service by Serverless.'},
      {id : 4, desc : 'Deploy Lambda into VPC with Internet Gateway and route Lambda in private subnet to public subnet through NAT Gateway with an Elastic IP.'},
      {id : 5, desc : 'Mongodb only allow connection from Elastic IP of the NAT Gateway.'},
      {id : 6, desc : 'Host static website and store user image in S3.'},
    ],
    link : 'https://d2gix9lwutbr87.cloudfront.net/'
  },

];