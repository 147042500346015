import classes from './Experience.module.css'; 
import { exp } from '../../data/exp';
import { achivements } from '../../data/achievements'
import { edu } from '../../data/edu';
import P from '../../UI/Ptext/P';
import { useContext } from 'react';
import { ThemeContext } from '../../context/theme';
const Experience = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.darkMode; 
    return (
            <div id="exp" className={classes.experience}>
                <div className={classes['exp-left']}>
                    <h3 className={classes['exp-heading']}>Experiences</h3>
                    <ul className={classes.timeline}>
                    {exp.map((item) => (
                        <li key={item.id} style={{backgroundColor:darkMode ? "#222" : "white" , color : darkMode && "white" }}>
                            <h4 style={{background:darkMode ? "white" : "#222" , color : darkMode ? "#222" : "white" }}><span>{item.date}</span> {item.position}</h4>
                            <P desc={item.desc}/>
                            <b>Company</b> - {item.company}
                        </li>
                        
                    ))}
                    </ul>
                </div>
                <div className={classes['exp-right']}>
                    <h3 className={classes['exp-heading']}>Education</h3>
                    <ul className={classes.timeline} style={{background:darkMode ? "#222" : "white" }}>
                        {edu.map((item) => (
                            <li key={item.id} style={{backgroundColor:darkMode ? "#222" : "white" , color : darkMode && "white" }}>
                                <h4 style={{background:darkMode ? "white" : "#222" , color : darkMode ? "#222" : "white" }} ><span>{item.date}</span> {item.course}</h4>
                                <p >{item.gpa} </p>
                                <b>School</b> - {item.school}
                            </li>
                        ))}
                    </ul>
                    <h3 className={classes['exp-heading']}>Achivements</h3>
                    <ul className={classes.timeline}>
                        {achivements.map((item) => (
                            <li key={item.id} style={{backgroundColor:darkMode ? "#222" : "white" , color : darkMode && "white" }}>
                                <h4 style={{background:darkMode ? "white" : "#222" , color : darkMode ? "#222" : "white"  }}><span>{item.date}</span> {item.name}</h4>
                                <p>{item.desc}</p>
                                <b>View {item.title}</b> - <a href={item.link} style={{color : darkMode ? "white" : "#222"  }} target="_blank" rel="noreferrer" >Link</a>
                            </li>
                        ))}               
                    </ul>
                </div>
            </div>
    )
}

export default Experience
