import classes from './Intro.module.css';
import { intro } from '../../data/intro';
import { ThemeContext } from '../../context/theme';
import { useContext } from 'react';
import resume from '../../assets/resume.pdf'
const Intro = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.darkMode; 

    const downloadResume = () => { 
        window.open(resume,'_blank').focus();
    }


    return (
        <div id="intro" className={classes.intro}>
            <div className={classes['intro-left']}>
                <div className={classes['intro-left-wrapper']}>
                    <h2 className={classes['intro-intro']}> Hello , My name is </h2>
                    <h1 className={classes['intro-name']}> Hau Yin </h1>
                    <div className={classes['intro-title']}>
                        {intro.map((item) => (
                            <div key={item.id} className={classes['intro-title-wrapper']}>
                                <div className={classes['intro-title-item']}>
                                    {item.desc}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={classes['intro-description']}>
                        <button style={{backgroundColor:darkMode ? "white" : "#616161" , color : darkMode ? "#222" : "white" }} className={classes['intro-button']} onClick={downloadResume}> Download CV</button>
                    </div>
                </div>
                <div className={classes['intro-scroll']}>
                    <div className={classes['intro-scroll-btn']}> 
                        <a href="#about-me"> 
                            <span style={{borderBottomColor:darkMode ? "white" : "#222" , borderRightColor : darkMode ? "white" : "#222" }}>

                            </span>
                        </a>
                    </div>
                </div>
            </div>
            {/* <div className={classes['intro-right']}>
                <div className={classes['intro-bg']}></div>
                <img src={me} alt="" className={classes['intro-img']}></img>
            </div> */}
        </div>
    )
}

export default Intro
