import classes from './Footer.module.css'
const Footer = () => {
    const d = new Date();
    let year = d.getFullYear();
    return (
        <div className={classes.footer}>
            <small>&copy; {year} - Hau Yin</small>
        </div>
    )
}

export default Footer
