
export const exp = [
  {
    id: 1,
    date: "Apr 2022 - Present",
    desc: [
        {id : 1 , description : 'In-charge of frontend and backend development.'},    
        {id : 2 , description : 'Develop backend services with Java Spring Boot.'},        
        {id : 3 , description : 'Responsible for website deployment.'},    
        {id : 4 , description : 'Use Scrum framework in development teams.'},    
    ],
    company : 'Singlife with Aviva',
    position : 'Software Engineer'

  },
  {
    id: 2,
    date: "Apr 2021 - Mar 2022",
    desc: [
        {id : 1 , description : 'In-charge of rebranding project for frontend development (Angular).'},    
        {id : 2 , description : 'Replatform existing project to new framework.'},        
        {id : 3 , description : 'Develop new page and integrate with backend services.'},    
        {id : 4 , description : 'Communicate efficiency with backend developer for integration.'},        
    ],
    company : 'Aviva Singapore',
    position : 'Application Analyst'

  },
  {
    id: 3,
    date: "Apr 2018 - Apr 2021",
    desc: [
        {id : 1 , description : 'Liased, obtained and manage software requirements from collegues from other countries..'},    
        {id : 2 , description : 'Led and guide junior develepors..'},    
        {id : 3 , description : 'Assessed project requirements using Agile principles..'},        
        {id : 4 , description : 'Develop new functionalities that benefits users..'},        
    ],
    company : 'JuzTalent',
    position : 'Web Developer'
  },
];