import { createContext, useState } from "react";



export const ThemeContext = createContext({ 
    darkMode : true,
    ToggleDarkMode : () => {}
}); 


export const ThemeProvider = ( props ) => { 
    const [darkMode , setDarkMode] = useState(true)
    
    
    function toggleDarkMode() { 
        setDarkMode(!darkMode)
    }

    const contextValue = {
        darkMode : darkMode, 
        ToggleDarkMode : toggleDarkMode
    }

   return (
       <ThemeContext.Provider value={contextValue}>
           {props.children}
       </ThemeContext.Provider>
   )
}