import Intro from "./components/Intro/Intro";
import About from "./components/About/About";
import ProjectList from "./components/ProjectList/ProjectList";
import Contact from "./components/Contact/Contact";
import Toogle from "./UI/Toogle/Toogle";
import { ThemeContext } from "./context/theme";
import { useContext, useState } from "react";
import Topbar from "./UI/Topbar/Topbar";
import Menu from "./UI/Menu/Menu";
import Footer from "./UI/Footer/Footer";
import Skills from "./components/Skills/Skills";
import Experience from "./components/Experience/Experience";
function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const theme = useContext(ThemeContext);
  const darkMode = theme.darkMode;
  return (
    <div
      style={{
        backgroundColor: darkMode ? "#222" : "white",
        color: darkMode && "white",
      }}
    >
      <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Toogle />
      <Intro />
      <About />
      <Skills />
      <Experience />
      <ProjectList />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
